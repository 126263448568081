exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jere-huttunen-astro-freelancer-js": () => import("./../../../src/pages/jere-huttunen/astro-freelancer.js" /* webpackChunkName: "component---src-pages-jere-huttunen-astro-freelancer-js" */),
  "component---src-pages-jere-huttunen-gatsby-freelancer-js": () => import("./../../../src/pages/jere-huttunen/gatsby-freelancer.js" /* webpackChunkName: "component---src-pages-jere-huttunen-gatsby-freelancer-js" */),
  "component---src-pages-jere-huttunen-index-js": () => import("./../../../src/pages/jere-huttunen/index.js" /* webpackChunkName: "component---src-pages-jere-huttunen-index-js" */),
  "component---src-pages-jere-huttunen-next-freelancer-js": () => import("./../../../src/pages/jere-huttunen/next-freelancer.js" /* webpackChunkName: "component---src-pages-jere-huttunen-next-freelancer-js" */),
  "component---src-pages-ota-yhteytta-js": () => import("./../../../src/pages/ota-yhteytta.js" /* webpackChunkName: "component---src-pages-ota-yhteytta-js" */),
  "component---src-pages-palvelut-astro-verkkokehitys-js": () => import("./../../../src/pages/palvelut/astro-verkkokehitys.js" /* webpackChunkName: "component---src-pages-palvelut-astro-verkkokehitys-js" */),
  "component---src-pages-palvelut-contentful-kehitys-js": () => import("./../../../src/pages/palvelut/contentful-kehitys.js" /* webpackChunkName: "component---src-pages-palvelut-contentful-kehitys-js" */),
  "component---src-pages-palvelut-designista-koodia-js": () => import("./../../../src/pages/palvelut/designista-koodia.js" /* webpackChunkName: "component---src-pages-palvelut-designista-koodia-js" */),
  "component---src-pages-palvelut-gatsby-verkkokehitys-js": () => import("./../../../src/pages/palvelut/gatsby-verkkokehitys.js" /* webpackChunkName: "component---src-pages-palvelut-gatsby-verkkokehitys-js" */),
  "component---src-pages-palvelut-hubspot-teeman-kehitys-js": () => import("./../../../src/pages/palvelut/hubspot-teeman-kehitys.js" /* webpackChunkName: "component---src-pages-palvelut-hubspot-teeman-kehitys-js" */),
  "component---src-pages-palvelut-next-verkkokehitys-js": () => import("./../../../src/pages/palvelut/next-verkkokehitys.js" /* webpackChunkName: "component---src-pages-palvelut-next-verkkokehitys-js" */),
  "component---src-pages-palvelut-sivunopeuden-optimointi-js": () => import("./../../../src/pages/palvelut/sivunopeuden-optimointi.js" /* webpackChunkName: "component---src-pages-palvelut-sivunopeuden-optimointi-js" */),
  "component---src-pages-palvelut-storyblok-kehitys-js": () => import("./../../../src/pages/palvelut/storyblok-kehitys.js" /* webpackChunkName: "component---src-pages-palvelut-storyblok-kehitys-js" */),
  "component---src-pages-palvelut-tekninen-seo-js": () => import("./../../../src/pages/palvelut/tekninen-seo.js" /* webpackChunkName: "component---src-pages-palvelut-tekninen-seo-js" */),
  "component---src-pages-tietoa-js": () => import("./../../../src/pages/tietoa.js" /* webpackChunkName: "component---src-pages-tietoa-js" */),
  "component---src-pages-tietosuojaseloste-js": () => import("./../../../src/pages/tietosuojaseloste.js" /* webpackChunkName: "component---src-pages-tietosuojaseloste-js" */)
}

